import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';

import Features from '@/components/home/features';
import PerkSection from '@/components/home/perk-section';

export default function KeyFeatures() {
  const { t } = useTranslation('pages/home');

  return (
    <div className='flex items-center justify-center'>
      <div className='flex w-full max-w-[1400px] flex-col gap-24 p-5 lg:pb-40'>
        <div className='mb-10 flex flex-col gap-10'>
          <h2 className='text-8-5xl font-bold leading-[110%] text-white'>
            <Trans i18nKey={t('key_features')} components={{ a: <Link href='/technology' /> }} />
          </h2>
          <p className='mb-0 pb-0 text-base font-medium leading-[140%] text-[#8A8A9A]'>{t('key_features_description')}</p>
        </div>
        <Features />
        <PerkSection />
      </div>
    </div>
  );
}
