import { useTranslation } from 'next-i18next';

export default function PerkSection() {
  const { t } = useTranslation('pages/home');

  return (
    <div className='grid grid-cols-1 gap-6 mobile:grid-cols-2 laptop:grid-cols-4'>
      <div className='flex w-full flex-col rounded-xl border border-[#20252E] px-4 pb-10 pt-16 mobile:pl-10 mobile:pr-0'>
        <div className='text-white'>
          <span className='text-8xl font-bold leading-[110%]'>2000</span>
          <span className='text-4xl'>+</span>
        </div>
        <div className='flex items-center gap-2.5'>
          <div className='h-[0.8125rem] w-[0.4375rem] bg-[#aef55c]' />
          <div className='text-xl font-bold leading-[110%] text-[#72727c]'>{t('perk_world_wide')}</div>
        </div>
      </div>
      <div className='flex w-full flex-col rounded-xl border border-[#20252E] px-4 pb-10 pt-16 mobile:pl-10 mobile:pr-0'>
        <div className='text-white'>
          <span className='text-8xl font-bold leading-[110%]'>2000</span>
          <span className='text-4xl'>+</span>
        </div>
        <div className='flex items-center gap-2.5'>
          <div className='h-[0.8125rem] w-[0.4375rem] bg-[#aef55c]' />
          <div className='text-xl font-bold leading-[110%] text-[#72727c]'>{t('perk_supported')}</div>
        </div>
      </div>
      <div className='flex w-full flex-col rounded-xl border border-[#20252E] px-4 pb-10 pt-12 mobile:pl-10 mobile:pr-0'>
        <div className='pt-1 text-white'>
          <div className='text-sm font-bold leading-[110%] text-white'>{t('perk_reduced_up_to')}</div>
          <span className='text-8xl font-bold leading-[110%]'>99%</span>
        </div>
        <div className='flex items-center gap-2.5'>
          <div className='h-[0.8125rem] w-[0.4375rem] bg-[#aef55c]' />
          <div className='text-xl font-bold leading-[110%] text-[#72727c]'>{t('perk_reduced_ping')}</div>
        </div>
      </div>
      <div className='flex w-full flex-col rounded-xl border border-[#20252E] px-4 pb-10 pt-12 mobile:pl-10 mobile:pr-0'>
        <div className='pt-1 text-white'>
          <div className='text-sm font-bold leading-[110%] text-white'>{t('perk_reduced_up_to')}</div>
          <span className='text-8xl font-bold leading-[110%]'>100%</span>
        </div>
        <div className='flex items-center gap-2.5'>
          <div className='h-[0.8125rem] w-[0.4375rem] bg-[#aef55c]' />
          <div className='text-xl font-bold leading-[110%] text-[#72727c]'>{t('perk_reduced_packet')}</div>
        </div>
      </div>
    </div>
  );
}
