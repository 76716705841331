import Image from 'next/image';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';

export default function Features() {
  const { t } = useTranslation('pages/home');
  const items = t('features', { returnObjects: true });

  return (
    <div className='relative'>
      <div className='grid grid-cols-1 gap-10 mobile:grid-cols-2 ipad:grid-cols-3'>
        {Object.entries(items).map(([key, value]) => (
          <Link href={value?.url} key={key} className='group flex w-full max-w-screen-sm-portrait flex-col gap-y-3'>
            <div className='size-9'>
              <Image width={36} height={36} src={value.iconSrc} alt='Icon' />
            </div>
            <div className=''>
              <span className='text-2xl font-bold uppercase leading-[normal] text-white transition group-hover:text-green-300'>{value.title}</span>
            </div>
            <p className='text-sm font-medium text-[#B3B3BE]'>
              <Trans key={value.description} components={{ strong: <strong /> }}>
                {value.description}
              </Trans>
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}
